var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"requests") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <li class=\"eruda-request "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasErr") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + "\" data-id=\""
    + alias3(alias2((data && lookupProperty(data,"key")), depth0))
    + "\"><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"name",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"status",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "</span><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"method",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"method") : depth0), depth0))
    + "</span><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"type",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"subType") : depth0), depth0))
    + "</span><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"size",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"size") : depth0), depth0))
    + "</span><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"time",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayTime") : depth0), depth0))
    + "</span></li> ";
},"3":function(container,depth0,helpers,partials,data) {
    return "eruda-error";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <li><span "
    + ((stack1 = lookupProperty(helpers,"class").call(depth0 != null ? depth0 : (container.nullContext || {}),"name",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">Empty</span></li> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"requests") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,})) != null ? stack1 : "");
},"useData":true});