var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul "
    + ((stack1 = lookupProperty(helpers,"class").call(depth0 != null ? depth0 : (container.nullContext || {}),"json",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></ul>";
},"useData":true});