var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"range",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"head",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"desc") : depth0), depth0))
    + " <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"val",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"val") : depth0), depth0))
    + "</span></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"input-container",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"range-track",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"range-track-bar",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"range-track-progress",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " style=\"width: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"progress") : depth0), depth0))
    + "%\"></div></div></div><input type=\"range\" min=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\" max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max") : depth0), depth0))
    + "\" step=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"step") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"val") : depth0), depth0))
    + "\"></div></div>";
},"useData":true});