var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"header",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "> "
    + ((stack1 = lookupProperty(helpers,"repeat").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"indentLevel") : stack1),{"name":"repeat","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + " <div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"time-container",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><span>"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"headers") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + "</span><span>"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"headers") : depth0)) != null ? lookupProperty(stack1,"from") : stack1), depth0))
    + "</span></div></div> ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div "
    + ((stack1 = lookupProperty(helpers,"class").call(depth0 != null ? depth0 : (container.nullContext || {}),"nesting-level",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div> ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"icon-container",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,lookupProperty(helpers,"concat").call(alias1,"icon icon-",(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"concat","hash":{},"data":data,}),{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span></div> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"headers") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + " <div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,lookupProperty(helpers,"concat").call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0)," log-item",{"name":"concat","hash":{},"data":data,}),{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "> "
    + ((stack1 = lookupProperty(helpers,"repeat").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"indentLevel") : stack1),{"name":"repeat","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + " <div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"count-container hidden",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"count",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"log-content-wrapper",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"log-content",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"msg") : depth0), depth0)) != null ? stack1 : "")
    + "</div></div></div>";
},"useData":true});