module.exports = {
  background: 'b',
  'background-image': 'bi',
  border: 'bo',
  'border-bottom': 'bb',
  'border-collapse': 'bc',
  'border-left-color': 'blc',
  'border-right': 'br',
  'border-radius': 'bra',
  'border-top': 'bt',
  'border-top-color': 'btc',
  'box-shadow': 'bs',
  'box-sizing': 'bsi',
  clear: 'cl',
  color: 'c',
  content: 'co',
  cursor: 'cu',
  display: 'd',
  flex: 'fl',
  'flex-shrink': 'fsh',
  float: 'f',
  'font-family': 'ff',
  'font-size': 'fs',
  'font-weight': 'fw',
  height: 'h',
  left: 'l',
  'line-height': 'lh',
  margin: 'm',
  'margin-bottom': 'mb',
  'margin-left': 'ml',
  'margin-top': 'mt',
  'min-height': 'mh',
  outline: 'ou',
  overflow: 'o',
  'overflow-x': 'ox',
  'overflow-y': 'oy',
  padding: 'p',
  'padding-bottom': 'pb',
  'padding-left': 'pl',
  'padding-top': 'pt',
  'pointer-events': 'pe',
  position: 'po',
  'text-align': 'ta',
  'text-transform': 'tt',
  top: 't',
  transition: 'tr',
  'user-select': 'us',
  'vertical-aligin': 'va',
  visibility: 'v',
  width: 'w',
  'will-change': 'wc',
  'white-space': 'ws',
  '-webkit-overflow-scrolling': 'wos',
  'z-index': 'z'
}
