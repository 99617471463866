var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"elements-highlight",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"indicator",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"margin",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"border",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"padding",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"content",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"size",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div></div>";
},"useData":true});