var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"switch",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"desc") : depth0), depth0))
    + " <label "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"checkbox",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><input type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"input",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"val") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + "> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"label",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias3,"handle",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span></label></div>";
},"useData":true});