var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <li><h2 "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"title",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h2><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"content",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"val") : depth0), depth0)) != null ? stack1 : "")
    + "</div></li> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul> "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"infos") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,})) != null ? stack1 : "")
    + " </ul>";
},"useData":true});